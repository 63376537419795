@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,700&display=swap');

body {
	font-family: 'Fira Sans', sans-serif;
	margin: 0;
	padding: 0;
	background-color: #003b3b;
	color: white;
	text-align: center;
}
