@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,700&display=swap);
body {
	font-family: 'Fira Sans', sans-serif;
	margin: 0;
	padding: 0;
	background-color: #003b3b;
	color: white;
	text-align: center;
}

.Cell {
	height: 100px;
	width: 100px;
	margin: none;
	border: 2px solid black;
}

.Cell-empty {
	background-color: darkgray;
}

.Cell-even {
	background-color: salmon;
	cursor: pointer;
}

.Cell-odd {
	background-color: teal;
	cursor: pointer;
}

.move-up {
	-webkit-transform: translateY(-100px);
	        transform: translateY(-100px);
}
.move-right {
	-webkit-transform: translateX(100px);
	        transform: translateX(100px);
}
.move-down {
	-webkit-transform: translateY(100px);
	        transform: translateY(100px);
}
.move-left {
	-webkit-transform: translateX(-100px);
	        transform: translateX(-100px);
}

.move-up,
.move-right,
.move-down,
.move-left {
	-webkit-transition: all 0.2s ease-out;
	transition: all 0.2s ease-out;
}

.Button {
	font-size: 1.1rem;
	border: 2px solid #7a6800;
	cursor: pointer;
	background-color: gold;
	border-radius: 15px;
	padding: 0.5rem 1rem;
	margin: 1rem;
	box-shadow: 0 0.2em #7a6800;
}

.Button:active {
	box-shadow: none;
	position: relative;
	top: 0.2em;
}

.Board {
	margin: 0 auto;
	background-color: darkgray;
	border: none;
	border-collapse: collapse;
}

.NavBar .Title {
	color: #e25646;
}

.NavBar nav {
	display: flex;
	justify-content: space-between;
	padding: 2rem 1rem;
	margin: 2rem;
}
.NavBar nav a {
	color: white;
	text-decoration: none;
}

.App {
	text-align: center;
}

